import React from "react";

const WhyUs = () => {
  return (
    <section id="why-us" className="why-us bg-light">
      <div className="container">
        <h2 className="why-us-text mb-4">¿Por qué usar Talento AI?</h2>
        <div className="why-us-row row align-items-center">
          <div className="col-md-6 why-us-image"></div>
          <div className="why-us-text col-md-6">
            <ul>
              <li>
                Información confiable y verificada por expertos, asegurando
                decisiones informadas y precisas.
              </li>
              <li>
                Conexión instantánea con especialistas, brindando asesoría
                personalizada en tiempo real.
              </li>
              <li>
                Programas personalizados que se ajustan a tus necesidades
                específicas, optimizando cada fase del reclutamiento.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
