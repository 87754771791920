import React from "react";

const Header = () => {
  return (
    <header className="header">
      <div className="overlay"></div>
      <div className="header-content">
        <h1>¡Te damos la bienvenida a Talento AI!</h1>
        <p>
          Elimina las barreras del reclutamiento tradicional y encuentra el
          talento perfecto de forma rápida y eficiente. Nuestra plataforma
          impulsada por IA agiliza el proceso de selección, optimiza la toma de
          decisiones y proporciona una experiencia positiva para todos.
        </p>
        <a href="https://company.talentoai.com.ar" className="join-button">
          Únete a Talento AI
        </a>
      </div>
    </header>
  );
};

export default Header;
