import React from "react";

const Footer = () => {
  return (
    <footer className="footer py-3 bg-primary text-white text-center mt-auto">
      <p className="mb-0">© 2024 Talento AI. Todos los derechos reservados.</p>
    </footer>
  );
};

export default Footer;
