import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getJobBySeoName, saveApplication } from "../services/jobService";

const ApplicationForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [alreadyApplied, setAlreadyApplied] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phoneNumbers: "",
    coverLetter: "",
  });

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const jobData = await getJobBySeoName(id);
        setJob(jobData);

        // Check if the user has already applied
        const appliedJobs =
          JSON.parse(localStorage.getItem("appliedJobs")) || [];
        if (appliedJobs.includes(jobData._id)) {
          setAlreadyApplied(true);
          setSuccessMessage("Ya te has postulado para este trabajo.");
        }
      } catch (error) {
        setError("Error fetching job details.");
      } finally {
        setLoading(false);
      }
    };

    fetchJob();
  }, [id]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type !== "application/pdf") {
      setFileError("El archivo debe ser un PDF");
      setFile(null);
    } else {
      setFileError(null);
      setFile(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!file) {
      setFileError("El archivo es obligatorio y debe ser un PDF");
      return;
    }

    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("submit");

    if (!token) {
      setErrorMessage("Por favor, verifica el reCAPTCHA.");
      return;
    }

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("job_id", job._id);
      formDataToSend.append("fullName", formData.fullName);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("phoneNumbers", formData.phoneNumbers);
      formDataToSend.append("coverLetter", formData.coverLetter);
      formDataToSend.append("file", file);
      formDataToSend.append("recaptchaToken", token);

      await saveApplication(formDataToSend);

      // Save to localStorage
      const appliedJobs = JSON.parse(localStorage.getItem("appliedJobs")) || [];
      appliedJobs.push(job._id);
      localStorage.setItem("appliedJobs", JSON.stringify(appliedJobs));

      setSuccessMessage("Aplicación enviada con éxito");
      setErrorMessage(null);

      // Clear form
      setFormData({
        fullName: "",
        email: "",
        phoneNumbers: "",
        coverLetter: "",
      });
      setFile(null);
    } catch (error) {
      setErrorMessage("Error enviando la aplicación");
      setSuccessMessage(null);
    }
  };

  if (loading) {
    return <div className="container py-5">Cargando...</div>;
  }

  if (error) {
    return <div className="container py-5">{error}</div>;
  }

  if (!job) {
    return <div className="container py-5">Trabajo no encontrado.</div>;
  }

  return (
    <div className="container my-5">
      <h2>{job.title}</h2>
      <p>
        <strong>Descripción:</strong>
      </p>
      <p>{job.description}</p>
      <hr />
      <p>Aplicar para esta posición</p>
      {successMessage && (
        <div className="alert alert-success">{successMessage}</div>
      )}
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
      <form onSubmit={handleSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="fullName">Full name</label>
          <input
            id="fullName"
            name="fullName"
            type="text"
            className="form-control"
            value={formData.fullName}
            onChange={handleChange}
            required
            disabled={alreadyApplied}
          />
          {formData.fullName === "" && (
            <p className="text-danger">Full name is required</p>
          )}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            name="email"
            type="email"
            className="form-control"
            value={formData.email}
            onChange={handleChange}
            required
            disabled={alreadyApplied}
          />
          {formData.email === "" && (
            <p className="text-danger">Email is required</p>
          )}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="phoneNumbers">Phone numbers</label>
          <input
            id="phoneNumbers"
            name="phoneNumbers"
            type="text"
            className="form-control"
            value={formData.phoneNumbers}
            onChange={handleChange}
            required
            disabled={alreadyApplied}
          />
          {formData.phoneNumbers === "" && (
            <p className="text-danger">Phone numbers are required</p>
          )}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="coverLetter">Carta de presentación</label>
          <textarea
            id="coverLetter"
            name="coverLetter"
            className="form-control"
            rows="3"
            value={formData.coverLetter}
            onChange={handleChange}
            required
            disabled={alreadyApplied}
          />
          {formData.coverLetter === "" && (
            <p className="text-danger">Cover letter is required</p>
          )}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="file">Curriculum</label>
          <input
            id="file"
            name="file"
            type="file"
            className="form-control"
            onChange={handleFileChange}
            required
            disabled={alreadyApplied}
          />
          {fileError && <p className="text-danger">{fileError}</p>}
          {file && <p>{file.name}</p>}
        </div>
        <hr />
        <button
          type="submit"
          className="btn btn-primary"
          disabled={alreadyApplied}
        >
          Aplicar
        </button>
        {"  "}
        <button
          type="button"
          className="btn btn-secondary ml-2"
          onClick={() => navigate(-1)}
        >
          Volver
        </button>
      </form>
    </div>
  );
};

export default ApplicationForm;
