import React, { useState, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    company: "",
    phone: "",
    message: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = () => {
    let tempErrors = {};
    if (!formData.fullName)
      tempErrors.fullName = "Nombre Completo es requerido.";
    if (!formData.email) {
      tempErrors.email = "Email es requerido.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email no es válido.";
    }
    if (!formData.company) tempErrors.company = "Empresa es requerido.";
    if (!formData.phone) tempErrors.phone = "Teléfono es requerido.";
    if (!formData.message) tempErrors.message = "Mensaje es requerido.";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      if (!validate()) return;

      setIsSubmitting(true);
      try {
        const token = await executeRecaptcha("submit");
        const response = await fetch(
          "https://api.talentoai.com.ar/api/v1/contact",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ ...formData, token }),
          }
        );
        if (response.ok) {
          toast.success("Mensaje enviado exitosamente.");
          setFormData({
            fullName: "",
            email: "",
            company: "",
            phone: "",
            message: "",
          });
          setErrors({});
        } else {
          toast.error("Error al enviar el mensaje.");
        }
      } catch (error) {
        console.error("Error al enviar el mensaje:", error);
        toast.error("Error al enviar el mensaje.");
      } finally {
        setIsSubmitting(false);
      }
    },
    [executeRecaptcha, formData]
  );

  return (
    <section id="contact" className="contact bg-dark text-white py-5">
      <div className="container">
        <h2 className="text-center mb-4">Contacto</h2>
        <form
          className="d-flex flex-column align-items-center"
          onSubmit={handleSubmit}
        >
          <div className="form-row w-100">
            <div className="form-group col-md-12">
              <label htmlFor="fullName">Nombre Completo *</label>
              <input
                type="text"
                className={`form-control ${errors.fullName && "is-invalid"}`}
                id="fullName"
                placeholder="Nombre Completo"
                value={formData.fullName}
                onChange={handleChange}
              />
              {errors.fullName && (
                <div className="invalid-feedback">{errors.fullName}</div>
              )}
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="email">Email *</label>
              <input
                type="email"
                className={`form-control ${errors.email && "is-invalid"}`}
                id="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <div className="invalid-feedback">{errors.email}</div>
              )}
            </div>
          </div>
          <div className="form-row w-100">
            <div className="form-group col-md-12">
              <label htmlFor="company">Empresa *</label>
              <input
                type="text"
                className={`form-control ${errors.company && "is-invalid"}`}
                id="company"
                placeholder="Empresa"
                value={formData.company}
                onChange={handleChange}
              />
              {errors.company && (
                <div className="invalid-feedback">{errors.company}</div>
              )}
            </div>
            <div className="form-group col-md-12">
              <label htmlFor="phone">Teléfono *</label>
              <input
                type="text"
                className={`form-control ${errors.phone && "is-invalid"}`}
                id="phone"
                placeholder="Teléfono"
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && (
                <div className="invalid-feedback">{errors.phone}</div>
              )}
            </div>
          </div>
          <div className="form-group w-100">
            <label htmlFor="message">Mensaje *</label>
            <textarea
              className={`form-control ${errors.message && "is-invalid"}`}
              id="message"
              rows="4"
              placeholder="Mensaje"
              value={formData.message}
              onChange={handleChange}
            ></textarea>
            {errors.message && (
              <div className="invalid-feedback">{errors.message}</div>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-primary mt-3"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Enviando..." : "Enviar"}
          </button>
        </form>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Contact;
