import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "./en/translation.json";
import esTranslation from "./es/translation.json";
import ptTranslation from "./pt/translation.json";
import frTranslation from "./fr/translation.json";

const resources = {
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
  pt: {
    translation: ptTranslation,
  },
  fr: {
    translation: frTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "es", // default language
  fallbackLng: "es",
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
