import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import Header from "./components/Header";
import RecentJobs from "./components/RecentJobs";
import Features from "./components/Features";
import Partners from "./components/Partners";
import WhyUs from "./components/WhyUs";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import JobDetail from "./components/JobDetail";

import "./App.css";
import JobApplication from "./components/jobApplication";

const App = () => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={"6LeL2BAqAAAAAHIaeZCHU2L_sCoGU6xFr8FSiXcJ"}
    >
      <Router>
        <div className="d-flex flex-column min-vh-100">
          <Routes>
            <Route
              path="/"
              element={
                <React.Fragment>
                  <Header />
                  <RecentJobs />
                  <Features />
                  <Partners />
                  <WhyUs />
                  <About />
                  <Contact />
                </React.Fragment>
              }
            />
            <Route path="/job/:id" element={<JobDetail />} />
            <Route path="/job/:id/application" element={<JobApplication />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </GoogleReCaptchaProvider>
  );
};

export default App;
