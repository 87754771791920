import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getJobBySeoName } from "../services/jobService";

const JobDetail = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const jobData = await getJobBySeoName(id);
        setJob(jobData);
      } catch (error) {
        setError("Error fetching job details.");
      } finally {
        setLoading(false);
      }
    };

    fetchJob();
  }, [id]);

  if (loading) {
    return <div className="container py-5">Cargando...</div>;
  }

  if (error) {
    return <div className="container py-5">{error}</div>;
  }

  if (!job) {
    return <div className="container py-5">Trabajo no encontrado.</div>;
  }

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  return (
    <div className="container py-5">
      <h2>{job.title}</h2>
      <Link
        to={`/job/${job.slug}/application`}
        className="btn btn-primary mt-3"
      >
        Postularse
      </Link>
      <h6 className="text-muted">{job.profile}</h6>
      <p>
        <strong>Tipo:</strong> {job.jobType}
      </p>
      {job.salaryType === "Comission" ? (
        <p>
          <strong>Salario:</strong> {job.salaryType}
        </p>
      ) : (
        <p>
          <strong>Salario:</strong> {formatCurrency(job.salaryMin)} -{" "}
          {formatCurrency(job.salaryMax)} {job.salaryType}
        </p>
      )}
      <p>
        <strong>Tipo de Empleo:</strong> {job.employmentType}
      </p>
      <p>
        <strong>Publicado:</strong>{" "}
        {new Date(job.publishDate).toLocaleDateString()}
      </p>
      <hr />
      <p>
        <strong>Descripción:</strong>
      </p>
      <p>{job.description}</p>
      <hr />
      <p>
        <strong>Requisitos Obligatorios:</strong>
      </p>
      <p>{job.mustRequirements}</p>
      <hr />
      <p>
        <strong>Requisitos Deseables:</strong>
      </p>
      <p>{job.niceToHave}</p>
      <hr />
      <p>
        <strong>Criterios de Aceptación:</strong>
      </p>
      <p>{job.acceptanceCriteria}</p>
      <Link
        to={`/job/${job.slug}/application`}
        className="btn btn-primary mt-3"
      >
        Postularse
      </Link>
    </div>
  );
};

export default JobDetail;
