import React from "react";
// import company1 from "../assets/company/aws.jpg";
// import company2 from "../assets/company/google.jpg";
// import company3 from "../assets/company/aws.jpg";

const Partners = () => {
  return (
    <section id="partners" className="partners bg-dark text-white py-5">
      <div className="container">
        <h2 className="text-center mb-4">Talento AI</h2>
      </div>
    </section>
    // <section id="partners" className="partners bg-dark text-white py-5">
    //   <div className="container">
    //     <h2 className="text-center mb-4">Empresas que nos apoyan</h2>
    //     <div className="row text-center">
    //       <div className="col-md-4">
    //         <img src={company1} alt="Empresa 1" className="img-fluid mb-3" />
    //         <p>Empresa 1</p>
    //       </div>
    //       <div className="col-md-4">
    //         <img src={company2} alt="Empresa 2" className="img-fluid mb-3" />
    //         <p>Empresa 2</p>
    //       </div>
    //       <div className="col-md-4">
    //         <img src={company3} alt="Empresa 3" className="img-fluid mb-3" />
    //         <p>Empresa 3</p>
    //       </div>
    //     </div>
    //   </div>
    // </section>
  );
};

export default Partners;
