import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getJobs } from "../services/jobService";

const RecentJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const fetchJobs = async () => {
      try {
        setIsLoading(true);
        const data = await getJobs();
        if (isMounted) {
          const formattedJobs = data.map((x) => {
            return {
              id: x.id,
              title: x.title,
              profile: x.profile === undefined ? "Generalistic" : x.profile,
              type: x.type,
              salary:
                x.salaryType !== "Comission"
                  ? `${x.salaryType} (${formatCurrency(
                      x.salaryMin || 0
                    )} - ${formatCurrency(x.salaryMax || 0)})`
                  : x.salaryType,
              slug: x.slug,
            };
          });
          setJobs(formattedJobs);
          setFilteredJobs(formattedJobs); // Inicialmente mostrar todos los trabajos
        }
      } catch (error) {
        console.error("Error fetching jobs:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchJobs();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSearch = async () => {
    setIsLoading(true);
    const results = jobs.filter(
      (job) =>
        job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.profile.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.salary.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredJobs(results);
    setIsLoading(false);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(value);
  };

  return (
    <section id="recent-jobs" className="recent-jobs bg-light py-5">
      <div className="container">
        <h2 className="text-center mb-4">Búsquedas Activas Más Recientes</h2>
        <div className="search-bar d-flex mb-4">
          <input
            type="text"
            className="form-control"
            placeholder="Buscar trabajos..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            disabled={isLoading}
          />
          <button
            className="btn btn-primary ml-2"
            onClick={handleSearch}
            disabled={isLoading}
          >
            {isLoading ? "Buscando..." : "Buscar"}
          </button>
        </div>
        {isLoading ? (
          <div className="text-center">Cargando...</div>
        ) : filteredJobs.length === 0 ? (
          <p className="text-center">No se encontraron trabajos.</p>
        ) : (
          <div className="jobs-list-container">
            {filteredJobs.map((job) => (
              <div key={job.id} className="card job-card">
                <div className="card-body">
                  <h5 className="card-title">{job.title}</h5>
                  <p className="card-text">Tipo: {job.type}</p>
                  <p className="card-text">Salario: {job.salary}</p>
                  <Link to={`/job/${job.slug}`} className="btn btn-primary">
                    Ver más
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default RecentJobs;
