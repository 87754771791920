import React from "react";

const About = () => {
  return (
    <section id="about" className="about bg-light">
      <div className="container">
        <h2 className="about-text mb-4">Acerca de Talento AI</h2>
        <div className="about-row row align-items-center">
          <div className="about-text col-md-6">
            <p>
              <strong>Misión:</strong> Revolucionar el proceso de selección
              mediante IA, proporcionando evaluaciones precisas y detalladas.
            </p>
            <p>
              <strong>Visión:</strong> Ser líderes en soluciones de IA para
              recursos humanos, promoviendo diversidad e inclusión.
            </p>
            <h3>Valores</h3>
            <ul>
              <li>Precisión</li>
              <li>Innovación</li>
              <li>Empatía</li>
              <li>Compromiso</li>
              <li>Transparencia</li>
            </ul>
          </div>
          <div className="col-md-6 about-image"></div>
        </div>
      </div>
    </section>
  );
};

export default About;
