import React from "react";

import evaluaciones from "../assets/img/evaluaciones.png";
import decisiones from "../assets/img/decisiones.png";
import optimizacion from "../assets/img/optimizacion.png";

const Features = () => {
  return (
    <section className="features bg-light">
      <div className="container">
        <h2 className="text-center mb-4">¿Qué te ofrecemos?</h2>

        <div className="feature row mb-4">
          <div className="col-md-6">
            <img
              src={evaluaciones}
              alt="Evaluaciones Preliminares"
              className="feature-image img-fluid"
            />
          </div>
          <div className="feature-text col-md-6">
            <h3>Evaluaciones Preliminares Impulsadas por IA</h3>
            <p>
              Nuestra plataforma utiliza la inteligencia artificial para
              analizar detalladamente las habilidades, la experiencia y la
              personalidad de los candidatos. A través de algoritmos avanzados,
              proporcionamos una evaluación preliminar rápida y objetiva que
              permite a los reclutadores identificar los mejores talentos de
              manera eficiente. Este enfoque reduce el sesgo humano y garantiza
              que solo los candidatos más adecuados avancen en el proceso de
              selección.
            </p>
          </div>
        </div>

        <div className="feature row mb-4 flex-row-reverse">
          <div className="col-md-6">
            <img
              src={decisiones}
              alt="Decisiones Informadas"
              className="feature-image img-fluid"
            />
          </div>
          <div className="feature-text col-md-6">
            <h3>Decisiones Informadas</h3>
            <p>
              Los reclutadores reciben informes detallados que les permiten
              tomar decisiones informadas, reduciendo el tiempo y los costos
              asociados a la selección de candidatos. Nuestros informes incluyen
              análisis de competencias, recomendaciones personalizadas y datos
              comparativos que facilitan la elección del candidato ideal. Al
              proporcionar una visión completa y precisa, ayudamos a las
              empresas a mejorar su tasa de éxito en la contratación y a
              construir equipos más fuertes y cohesionados.
            </p>
          </div>
        </div>

        <div className="feature row mb-4">
          <div className="col-md-6">
            <img
              src={optimizacion}
              alt="Optimización del Proceso"
              className="feature-image img-fluid"
            />
          </div>
          <div className="feature-text col-md-6">
            <h3>Optimización del Proceso</h3>
            <p>
              La automatización de tareas repetitivas libera tiempo para que los
              reclutadores se concentren en actividades de mayor valor, como la
              construcción de relaciones con los candidatos. Nuestra plataforma
              maneja tareas administrativas como la programación de entrevistas
              y el seguimiento de aplicaciones, permitiendo a los reclutadores
              enfocarse en la evaluación cualitativa y en la experiencia del
              candidato. Este enfoque no solo aumenta la eficiencia, sino que
              también mejora la satisfacción tanto del reclutador como del
              candidato, creando un proceso de contratación más humano y
              efectivo.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
