import axios from "axios";

export const getJobs = async () => {
  try {
    const response = await fetch("https://api.talentoai.com.ar/api/v1/jobs");
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching jobs:", error);
    return [];
  }
};

export const getJob = async (id) => {
  try {
    const response = await fetch(
      `https://api.talentoai.com.ar/api/v1/jobs/${id}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching jobs:", error);
    return [];
  }
};

export const getJobBySeoName = async (seoName) => {
  try {
    const response = await fetch(
      `https://api.talentoai.com.ar/api/v1/jobs/${seoName}`
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error("Error fetching jobs:", error);
    return [];
  }
};

export const saveApplication = async (applicationData) => {
  try {
    const response = await axios.post(
      "https://api.talentoai.com.ar/api/v1/jobs/application",
      applicationData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error saving application:", error);
    throw error;
  }
};
